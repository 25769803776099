import React from 'react'
import AdminLayout from '../../../../Layout/AdminLayout'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Deferred, useForm } from '@inertiajs/react'

export default function Import({ link }) {
    const { data, setData, post, processing, errors } = useForm({
        start: new Date(),
        end: new Date(),
        exporting: '',
    })
    function handleExport(e) {
        e.preventDefault()
        //safety
        if (data.exporting === '') {
            post(route('participant.export'))
        }
        if (data.exporting === 'participant') {
            post(route('participant.export'))
        }
        if (data.exporting === 'syringex') {
            post(route('syringe.export'))
        }
        if (data.exporting === 'drop-in') {
            post(route('dropin.export'))
        }
        if (data.exporting === 'holistic') {
            post(route('holistic.export'))
        }
        if (data.exporting === 'narcan/naloxone') {
            post(route('narcan.export'))
        }
        if (data.exporting === 'smokingkit') {
            post(route('smoking.export'))
        }
        if (data.exporting === 'vaccineService') {
            post(route('vaccine.export'))
        }
        if (data.exporting === 'alerts') {
            post(route('alerts.export'))
        }
    }

    return (
        <>
            <AdminLayout title="Import/Export">
                <div className="flex flex-col flex-wrap w-full p-8 mt-4 overflow-x-auto bg-white shadow-md md:p-16">
                    <h2 className="mt-4 mb-2 text-2xl font-semibold">Export</h2>
                    <p className="mb-4">
                        To export data, first select the export type, start and
                        end date, and then click generate. When the export
                        button appears, click it to initiate the download.
                    </p>
                    <form onSubmit={handleExport}>
                        <div className="flex flex-row flex-wrap gap-2 mt-2">
                            <button
                                type="button"
                                className={
                                    data.exporting === 'participant'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() =>
                                    setData('exporting', 'participant')
                                }
                            >
                                Participant intake
                            </button>
                            <button
                                type="button"
                                className={
                                    data.exporting === 'syringex'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() => setData('exporting', 'syringex')}
                            >
                                Syringe / Harm Reduction Services
                            </button>
                            <button
                                type="button"
                                className={
                                    data.exporting === 'drop-in'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() => setData('exporting', 'drop-in')}
                            >
                                Drop-in
                            </button>
                            <button
                                type="button"
                                className={
                                    data.exporting === 'narcan/naloxone'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() =>
                                    setData('exporting', 'narcan/naloxone')
                                }
                            >
                                Narcan/Naxolone recipient
                            </button>
                            <button
                                type="button"
                                className={
                                    data.exporting === 'holistic'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() => setData('exporting', 'holistic')}
                            >
                                Holistic Services
                            </button>
                            <button
                                type="button"
                                className={
                                    data.exporting === 'smokingkit'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() =>
                                    setData('exporting', 'smokingkit')
                                }
                            >
                                Smoking Kit
                            </button>
                            <button
                                type="button"
                                className={
                                    data.exporting === 'vaccineService'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() =>
                                    setData('exporting', 'vaccineService')
                                }
                            >
                                Vaccine Service
                            </button>
                            <button
                                type="button"
                                className={
                                    data.exporting === 'alerts'
                                        ? 'bg-customGreen text-white px-4 py-2'
                                        : 'bg-gray-200 px-4 py-2'
                                }
                                onClick={() => setData('exporting', 'alerts')}
                            >
                                Alerts
                            </button>
                        </div>
                        <div className="flex flex-row gap-2 mt-6">
                            <div className="flex">
                                <div className="flex flex-col space-y-2">
                                    <p>From (start date)</p>
                                    <DatePicker
                                        selected={data.start}
                                        onChange={(date) =>
                                            setData('start', date)
                                        }
                                        className="w-full h-10 pl-2 text-gray-700 border border-gray-400"
                                    />
                                </div>
                            </div>
                            <div className="flex">
                                <div className="flex flex-col space-y-2">
                                    <p>To (end date)</p>
                                    <DatePicker
                                        selected={data.end}
                                        onChange={(date) =>
                                            setData('end', date)
                                        }
                                        className="w-full h-10 pl-2 text-gray-700 border border-gray-400"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-4 mt-4">
                            <button
                                className="flex-grow-0 px-4 py-2 bg-gray-100 hover:bg-gray-200"
                                type="submit"
                            >
                                Generate
                            </button>
                        </div>
                    </form>
                    {/* <Deferred data="link" fallback={<div>Loading...</div>}>
                        <form method="POST">
                            <a
                                className="bg-gray-100 px-4 py-2 w-48 flex justify-center items-center"
                                href={'/settings/exports/' + link}
                            >
                                Download export
                            </a>
                        </form>
                    </Deferred> */}
                </div>
            </AdminLayout>
        </>
    )
}
