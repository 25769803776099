import AdminLayout from '../../../../Layout/AdminLayout'

export default function Changelog() {
    return (
        <AdminLayout title="General Settings">
            <div className="flex flex-col flex-wrap w-full bg-white shadow-md p-8 md:p-16 mt-4 gap-4">
                <div className="flex flex-col gap-2">
                    <h2 className="text-xl font-bold">Version 1.0.1 (03/07/2025)</h2>
                    <ul className="list-disc list-inside">
                        <li>resolved error when sending out emails</li>
                        <li>resolved 405 error when exporting</li>
                    </ul>
                </div>
                <div className="flex flex-col gap-2">
                    <h2 className="text-xl font-bold">Version 1.0.0 (03/03/2025)</h2>
                    <ul className="list-disc list-inside">
                        <li>added changelog page</li>
                        <li>updated all back end packages and frameworks</li>
                        <li>fixed bug that might cause 500 error when hiding a participant</li>
                        <li>new alerts system</li>
                    </ul>
                </div>
            </div>
        </AdminLayout>
    )
}