import React, { useEffect, useState, useRef } from 'react'
import { Link, usePage } from '@inertiajs/react'
// import MinimizeButton from '@/Layout/Components/MinimizeButton'
import MinimizeButton from '../Components/MinimizeButton'
// import MobileHamburger from '@/Layout/Components/MobileHamburger'
import MobileHamburger from '../Components/MobileHamburger'
// import NavItem from '@/Layout/Components/NavItem'
import NavItem from '../Components/NavItem'
import NavDropdownItem from '../Components/NavDropdownItem'
// import NavPopOut from '@/Layout/Components/NavPopOut'
import NavPopOut from '../Components/NavPopOut'

export default function MobileNav({ showMobileNav, setShowMobileNav }) {
    const { user } = usePage().props
    const mobileNavRef = useRef(null)
    const [dropdownShow, setDropdownShow] = useState(false)
    const isActive = route().current()

    const handleClickOutside = (e) => {
        if (mobileNavRef.current.contains(e.target)) {
            // inside click
            return
        }
        // outside click
        setShowMobileNav(false)
    }

    useEffect(() => {
        if (showMobileNav) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [showMobileNav])

    return (
        <>
            <div
                className={
                    showMobileNav
                        ? 'fixed inset-0 bg-gray-400 opacity-50'
                        : 'bg-none'
                }
            >
                {' '}
            </div>
            <div>
                <div className="fixed bottom-2 right-4" ref={mobileNavRef}>
                    <div
                        className={
                            showMobileNav === true
                                ? 'w-64 flex flex-col justify-start bg-white shadow-lg rounded-md'
                                : 'hidden'
                        }
                    >
                        <div className="z-50">
                            <NavItem
                                name="Dashboard"
                                link="/dashboard"
                                icon="Map"
                                iconColor={
                                    isActive === 'dashboard'
                                        ? '#FFFFFF'
                                        : 'rgba(251, 212, 24, 1)'
                                }
                                shadowColor="shadow-yellow"
                                activeColor="bg-customYellow"
                                hasExtraIcon="false"
                                dropdownShow={dropdownShow}
                                isActive={
                                    isActive === 'dashboard' ? true : false
                                }
                            />
                            <div
                                className="relative z-50"
                                onClick={() => setDropdownShow(!dropdownShow)}
                            >
                                <NavDropdownItem
                                    name="Forms"
                                    icon="DocumentText"
                                    iconColor={
                                        isActive === 'intake' ||
                                            isActive === 'create2' ||
                                            isActive === 'create3' ||
                                            isActive === 'create4' ||
                                            isActive === 'exchange.show' ||
                                            isActive === 'dropin.show' ||
                                            isActive === 'vaccine.show' ||
                                            isActive === 'naloxone'
                                            ? '#FFFFFF'
                                            : 'rgba(100, 116, 175, 1)'
                                    }
                                    shadowColor="shadow-blue"
                                    activeColor="bg-customBlue"
                                    hasExtraIcon="true"
                                    extraIcon="ChevronRightIcon"
                                    isActive={
                                        isActive === 'intake' ||
                                            isActive === 'create2' ||
                                            isActive === 'create3' ||
                                            isActive === 'create4' ||
                                            isActive === 'exchange.show' ||
                                            isActive === 'vaccine.show' ||
                                            isActive === 'dropin.show' ||
                                            isActive === 'naloxone'
                                            ? true
                                            : false
                                    }
                                    dropdownShow={dropdownShow}
                                />
                                {dropdownShow && <NavPopOut />}
                            </div>
                            <NavItem
                                name="Search"
                                link="/participants/search"
                                icon="Search"
                                hasExtraIcon="false"
                                iconColor={
                                    isActive === 'search'
                                        ? 'rgba(198, 100, 51, 1)'
                                        : '#F3F4F6'
                                }
                                iconStroke={
                                    isActive === 'search'
                                        ? '#FFFFFF'
                                        : 'rgba(198, 100, 51, 1)'
                                }
                                shadowColor="shadow-orange"
                                activeColor="bg-customOrange"
                                isActive={isActive === 'search' ? true : false}
                                dropdownShow={dropdownShow}
                            />
                            {user.can.create_location && (
                                <NavItem
                                    name="Admin"
                                    link="/settings/locations"
                                    icon="Cog"
                                    hasExtraIcon="false"
                                    iconColor="rgba(142, 63, 120, 1)"
                                    shadowColor="shadow-purple"
                                    activeColor="bg-customPurple"
                                    dropdownShow={dropdownShow}
                                />
                            )}
                            <NavItem
                                name="Logout"
                                link="/logout"
                                icon="Logout"
                                hasExtraIcon="false"
                                iconColor="rgba(128, 128, 128, 1)"
                                shadowColor="shadow-gray"
                                activeColor="bg-gray-700"
                                dropdownShow={dropdownShow}
                            />
                            <div className="flex flex-row justify-center items-center p-2">
                                <small className="text-xs text-gray-500 text-center">1.01</small>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <MobileHamburger
                            showMobileNav={showMobileNav}
                            setShowMobileNav={setShowMobileNav}
                            icon="Menu"
                            iconColor="gray"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
