import React, { useState, useEffect, useRef } from 'react'
import { Link, usePage } from '@inertiajs/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

export default function StatusBar({ title, participant }) {
    const { user } = usePage().props
    const [showDropUser, setShowDropUser] = useState(false)
    const [showDropLocation, setShowDropLocation] = useState(false)
    const userDropdownRef = useRef(null)
    const locationDropdownRef = useRef(null)

    useEffect(() => {
        const pageClickEvent = (e) => {
            // Check if click is outside the user dropdown
            if (userDropdownRef.current && !userDropdownRef.current.contains(e.target)) {
                setShowDropUser(false)
            }
            // Check if click is outside the location dropdown
            if (locationDropdownRef.current && !locationDropdownRef.current.contains(e.target)) {
                setShowDropLocation(false)
            }
        }

        if (showDropUser || showDropLocation) {
            window.addEventListener('click', pageClickEvent)
        }

        return () => {
            window.removeEventListener('click', pageClickEvent)
        }
    }, [showDropUser, showDropLocation])

    function renderIsSeeing() {
        if (user.is.currently_seeing == null) {
            return (
                <div className="bg-customRed text-sm md:text-md text-white p-2 rounded-md font-medium">
                    <p>You are not seeing anyone</p>
                </div>
            )
        }
        return (
            <div className="flex-row flex items-center text-gray-600">
                <p className="hidden md:block">Now seeing ID #:</p>
                <div
                    className="relative flex flex-row items-center w-auto md:w-54 h-auto p-2 ml-1 hover:bg-gray-300 bg-gray-200 rounded-md"
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowDropUser(!showDropUser)
                        setShowDropLocation(false)
                    }}
                >
                    {user.is.vax_stat == null && (
                        <span className="block h-2 w-2 rounded-full bg-gray-100 mr-2"></span>
                    )}
                    {user.is.vax_stat == 0 && (
                        <span className="block h-2 w-2 rounded-full bg-customRed mr-2"></span>
                    )}
                    {user.is.vax_stat == 1 && (
                        <span className="block h-2 w-2 rounded-full bg-customYellow mr-2"></span>
                    )}
                    {user.is.vax_stat == 2 && (
                        <span className="block h-2 w-2 rounded-full bg-customGreen mr-2"></span>
                    )}
                    <p className="text-gray-900">{user.is.currently_seeing}</p>
                    <ChevronDownIcon
                        className={
                            showDropUser === true
                                ? 'h-5 w-5 text-gray-900 ml-1 transition transform rotate-270 md:rotate-180'
                                : 'h-5 w-5 text-gray-900 ml-1 transition transform rotate-90 md:rotate-0'
                        }
                    />
                    {showDropUser == true && (
                        <Link
                            ref={userDropdownRef}
                            method="POST"
                            className="text-gray-900 bg-white shadow-lg rounded-sm hover:bg-gray-100 p-4 h-auto w-56 absolute top-12 left-0 z-50"
                            href="/participants/drop"
                        >
                            Drop current participant
                        </Link>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-row items-bottom justify-center h-auto pt-4 w-full bg-gray-100 col-span-12 mx-4 md:mx-0">
            <div className="flex flex-row justify-center gap-8 items-center">
                <small className="hidden lg:block text-xs text-gray-500">1.01</small>
                <div className="flex-row flex items-center text-gray-600">
                    {renderIsSeeing()}
                </div>
                <div className="flex-row flex items-center text-gray-600">
                    <p className="hidden md:block">Location:</p>
                    <div
                        className="relative flex flex-row items-center w-auto md:w-54 h-auto p-2 ml-1 hover:bg-gray-300 bg-gray-200 rounded-md"
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowDropLocation(!showDropLocation)
                            setShowDropUser(false)
                        }}
                    >
                        <p className="text-gray-900">{user.is.currently_at}</p>
                        <ChevronDownIcon
                            className={
                                showDropLocation === true
                                    ? 'h-5 w-5 text-gray-900 ml-1 transition transform rotate-270 md:rotate-180'
                                    : 'h-5 w-5 text-gray-900 ml-1 transition transform rotate-90 md:rotate-0'
                            }
                        />
                        {showDropLocation == true && (
                            <Link
                                ref={locationDropdownRef}
                                as="button"
                                method="POST"
                                className="text-gray-900 bg-white shadow-lg rounded-sm hover:bg-gray-100 p-4 h-auto w-56 absolute top-12 right-0 md:left-0 z-50"
                                href="/location/drop"
                            >
                                Drop current location
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex-row items-center text-gray-600 hidden md:flex">
                    <p className="hidden md:block">Signed In As:</p>
                    <p className="text-gray-900 pl-1 text-sm md:text-lg">
                        {user.is.named}
                    </p>
                </div>
            </div>
        </div>
    )
}
