require('./bootstrap')
import { App } from '@inertiajs/inertia-react'
import { FlareErrorBoundary } from '@flareapp/flare-react'
import { InertiaProgress } from '@inertiajs/progress'
import React from 'react'
import { render } from 'react-dom'
import 'tailwindcss/tailwind.css'
import { flare } from '@flareapp/flare-client'

const el = document.getElementById('app')
InertiaProgress.init()
if (process.env.NODE_ENV === 'production') {
    flare.light('wGQgdJu2WyAnJDxMR0fZj7UmRxtGES07')
}

render(
    <FlareErrorBoundary>
        <App
            initialPage={JSON.parse(el.dataset.page)}
            resolveComponent={(name) =>
                import(`./Pages/${name}`).then((module) => module.default)
            }
        />
    </FlareErrorBoundary>,
    el
)
